
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import {i18n} from './i18n.js'

import '../public/css/style.scss';
import 'promise-polyfill/src/polyfill';

import VueMeta from 'vue-meta';

Vue.use(VueMeta);

new Vue({
	i18n,
	router:router,
	render: h => h(App),
}).$mount('#app')
