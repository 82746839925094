<template>
  <div class="characters-page page-wrapper">
		<img class="confetti" src="./../assets/mainPage/confetti.svg">
    <div class="page-content">
      <div class="section section-1">
        <img class="img hero-img" src="./../content_images/characters/hero.png">
        <img class="img hero-img-mob" src="./../content_images/characters/hero-mob.png">
      </div>
      <div class="section section-character">
        <div class="title">{{$t('characters')}}</div>
        <img class="baloons" src="./../assets/gerland-with-baloons.svg">
        <img class="mob-baloons" src="./../assets/programPage/decor4.svg">
        <div class="character-list">
          <template v-for="(item, i) in 100">
            <router-link to="/price"  class="character-list__item" :key="i" v-if="!$t('character_' + (i + 1)).includes('_')">
              <img :src="require(`./../content_images/characters/${i + 1}.png`)">
              <div class="title">{{$t('character_' + (i + 1))}}</div>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Characters',
  metaInfo: {
    title: 'Kostymer og maskot',
    meta: [
      {
        name: 'description',
        content: 'Fra prinsesser til superhelter, vi tilbyr et bredt utvalg av figurer for en magisk barnebursdag!'
      }
    ]
  },
  props: [],
  components: {
    
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}

</script>