<template>
  <div class="reviews-page page-wrapper">
    <div class="page-content">
      <img class="banner" src="./../assets/reviewsPage/hero.jpg">
      <div class="section section-1">
        <div class="section-wrapper">
          <img class="confetti" src="./../assets/programPage/confetti.svg">
          <div class="title">
            <img class="cap" src="./../assets/reviewsPage/cap.svg">
            <img class="baloons3" src="./../assets/reviewsPage/3balloons.svg">
            {{$t('reviews')}}
          </div>
          <div class="list">
            <div class="item" v-for="(item, i) in $parent.reviews" :key="i">
              <div class="avatar">
                <img class="img" :src="require(`./../content_images/avatars/${item.avatar}.jpg`)">
              </div>
              <div class="text">
                <div class="name">{{item.name}}</div>
                <div class="date">{{item.date}}</div>
                <div class="desc">{{item.desc}}</div>
              </div>
              <div class="item-gallery" v-if="item.photosCount">
                <div class="img-container" v-for="num in item.photosCount" :key="num">
                  <img class="img" v-if="num <= item.photosCount" :src="require(`./../content_images/reviews/${item.id}/${num}.jpg`)" @click="openGalleryModal(item.id,num)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  name: 'Reviews',
  metaInfo: {
    title: 'Aktiviteter for barn i Haugesund og Rogaland',
    meta: [
      {
        name: 'description',
        content: 'Oversikt over alle arrangementer på Haugalandet for familier med barn. Kom og hils på påskeharen i Haugesund eller få en fin ansiktsmaling.'
      }
    ]
  },
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    openGalleryModal(id,imgIndex) {
      this.$emit('openGalleryModal', id, imgIndex);
    }
  }
}
</script>