<template>
  <div id="app" class="app">
      <div class="loader" v-if="!pageLoaded">

      </div>
    <transition name="fade">
      <div id="page" class="page" v-show="pageLoaded" :class="[{'blured': bluredOverlay}, lang]">
        <transition name="fade">
          <Menu v-if="menuVisible" @closeMenu="closeMenu"/>
        </transition>
        <div class="menu-link mob" @click="toggleMenu" :class="{'active': menuVisible}">
          <div class="menu-link-img">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <Header @changeLang="changeLang" :lang="lang"/>
        <router-view @openFormModal="openFormModal" @openGalleryModal="openGalleryModal" @setActiveProgramId="setActiveProgramId" @setPageNotFound="setPageNotFound" @toAddParty="toAddParty" @fromAddParty="fromAddParty"/>
        <Footer v-if="!pageNotFound" @toAddParty="toAddParty"/>
        <cookie-law buttonText="ok">
          <div slot="message">
            {{$t('cookies_message')}} <router-link to="/privacy">{{$t('cookies_link')}}</router-link>
          </div>
        </cookie-law>
      </div>
    </transition>
    <div :class="['modal form-modal', {'active':formModalIsVisible}]">
      <div class="overlay" @click="closeFormModal"></div>
      <div class="modal-wrapper">
        <img class="img close" src="./assets/singleProgramPage/icon_close.svg" @click="closeFormModal">
        <form :class="['form', {'disabled': formSubmited}]" @submit.prevent="submit">
          <div class="title">{{$t('i_want_to_order_a_party')}}</div>
          <label :class="{'error': errors.includes('name_error')}">
            <input class="input" v-model="name" id="name" type="text" @focus="namePlaceholder = false" @blur="namePlaceholder = true">
            <div class="placeholder" v-if="namePlaceholder && !name">{{$t('name')}}</div>
            <div class="error-text">{{$t('name_error')}}</div>
          </label>
          <label :class="{'error': errors.includes('phone_error')}">
            <input class="input" id="phone" v-model="phoneNumber" type="text" @focus="phonePlaceholder = false" @blur="phonePlaceholder = true">
            <div class="placeholder" v-if="phonePlaceholder && !phoneNumber">{{$t('phoneNumber')}}</div>
            <div class="error-text">{{$t('phone_error')}}</div>
          </label>
          <textarea id="message" :placeholder="$t('whatProgramDoYouWant')" v-model="message"></textarea>
          <label class="checkbox" :class="{'error': errors.includes('check_error')}">
            <input class="checkbox-input" v-model="dataUse" type="checkbox">
            <div class="checkbox-mark"></div>
            <div class="checkbox-text">{{$t('i_consent')}}</div>
            <div class="error-text">{{$t('check_error')}}</div>
          </label>
          <button type="submit" class="blue-button hover-link">{{$t('submit')}}</button>
        </form>
        <div :class="['thank-you', {'active': formSubmited}]">
          <img class="img" src="./assets/singleProgramPage/icon_confetti.svg">
          <div class="title">{{$t('thank_you')}}</div>
          <div class="desc">{{$t('we_will_contact_you_soon')}}</div>
          <router-link to="/" class="blue-button hover-link" @click.native="closeFormModal">{{$t('to_main_page')}}</router-link>
        </div>
      </div>
    </div>
    <div :class="['modal gallery-modal gallery', {'active':galleryModalIsVisible}]">
      <div class="overlay" @click="closeGalleryModal"></div>
      <div class="gallery-modal-wrapper">
        <img class="img" v-if="activeProgramId && activeGalleryImgIndex" :src="require(`./content_images/programs/${activeProgramId}/${activeGalleryImgIndex}.jpg`)" @click="closeGalleryModal">
        <img class="img" v-if="!activeProgramId && reviewId && activeGalleryImgIndex" :src="require(`./content_images/reviews/${reviewId}/${activeGalleryImgIndex}.jpg`)" @click="closeGalleryModal">
        <img class="img" v-if="!activeProgramId && !reviewId && activeGalleryImgIndex" :src="require(`./content_images/partnership/${activeGalleryImgIndex}.jpg`)" @click="closeGalleryModal">
      </div>
      <div class="slick-buttons">
        <button class="slick-prev slick-arrow" type="button" @click="toPrevImg"></button>
        <button class="slick-next slick-arrow" type="button" @click="toNextImg"></button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'

import reviews from './content/reviews.json'
import Vue from 'vue'
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

  import CookieLaw from 'vue-cookie-law'


import emailjs from 'emailjs-com';

export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    CookieLaw
  },
  data: function() {
    return {
     bluredOverlay: false,
     menuVisible: false,
     lang: 'no',
     navList: ['price', 'characters', 'Events', 'partnership'],
     formModalIsVisible: false,
     galleryModalIsVisible: false,
     name: "",
     phoneNumber: "",
     message: "",
     dataUse: false,
     namePlaceholder: true,
     phonePlaceholder: true,
     formSubmited: false,
     activeProgramId: null,
     activeGalleryImgIndex: null,
     reviews: reviews,
     reviewId: null,
     pageNotFound: false,
     addPartyState: false,
     pageLoaded: false,
     errors: [],
     cookieStatus: null,
    }
  },
  watch: {
    $route() {
      this.closeMenu();
      window.scrollTo(0, 0);
      this.activeProgramId = 0;
      this.activeGalleryImgIndex = 0
      this.reviewId = 0;
      this.pageNotFound = false;

      this.pageLoaded = false
      setTimeout(() => {
        this.pageLoaded = true
      }, 500);
    }
  },
  methods: {
    cookiesStatus (status) {
        this.cookieStatus = status
    },
    coockiesAccept() {
      this.cookieStatus = 'accept';
    },
    setPageNotFound() {
      this.pageNotFound = true;
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    changeLang(l) {
      this.lang = l;
    },
    activateBlur() {
      this.bluredOverlay = true;
    },
    deactivateBlur() {
      this.bluredOverlay = false;
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    openFormModal() {
      this.formModalIsVisible = true;
    },
    closeFormModal() {
      let self = this;
      self.formModalIsVisible = false;
      setTimeout(() => {
        self.formSubmited = false;
        self.errors = [];
        self.name = '';
        self.email = '';
        self.message = '';
        self.dataUse = false;
      }, 200);
    },
    closeGalleryModal() {
      let self = this;
      self.galleryModalIsVisible = false;
    },
    setActiveProgramId(id) {
      this.activeProgramId = id;
    },
    openGalleryModal(id, imgIndex) {
      this.galleryModalIsVisible = true;
      this.activeGalleryImgIndex = imgIndex;
      this.reviewId = id;
    },
    toPrevImg() {
      let f;
      if (this.activeProgramId) {
        f = require(`./content_images/programs/${this.activeProgramId}/0.json`);
      } else if (this.reviewId && !this.activeProgramId) {
        f = reviews[this.reviewId - 1].photosCount;
      } else {
        f = require(`./content_images/partnership/0.json`);
      }
      let t = this.activeGalleryImgIndex;
      for (let i = 1; i <= f; i++) {
        if (1 == t) {
          this.activeGalleryImgIndex = f;
        } else if (i == t) {
          this.activeGalleryImgIndex = i - 1;
        }
      }
    },
    toNextImg() {
      let f;
      if (this.activeProgramId) {
        f = require(`./content_images/programs/${this.activeProgramId}/0.json`);
      } else if (this.reviewId && !this.activeProgramId) {
        f = reviews[this.reviewId - 1].photosCount;
      } else {
        f = require(`./content_images/partnership/0.json`);
      }
      let t = this.activeGalleryImgIndex;
      for (let i = 1; i <= f; i++) {
        if (f == t) {
          this.activeGalleryImgIndex = 1;
        } else if (i == t) {
          this.activeGalleryImgIndex = i + 1;
        }
      }
    },
    toAddParty() {
      this.addPartyState = true;
    },
    fromAddParty() {
      this.addPartyState = false;
    },
		submit() {
      this.templateParams = {
        name: this.name,
        phoneNumber: this.phoneNumber,
        message: this.message
      };
      if ( this.name && this.phoneNumber && this.dataUse ) {
        emailjs.send('service_nsnxajw', 'template_15urxyr', this.templateParams, 'user_78fZ3Ga0rOwsL2CE38Krm');

        this.errors = [];
        this.name = '',
        this.phoneNumber = '',
        this.message = '',
        this.dataUse = false,
        this.formSubmited = true;
        return
      }
      this.errors = [];
      
      if (!this.phoneNumber) {
        this.errors.push('phone_error');
        document.getElementById('phone').focus();
      }

      if (!this.name) {
        this.errors.push('name_error');
        document.getElementById('name').focus();
      }
      if (!this.dataUse) {
        this.errors.push('check_error');
      }
    }
  },
  mounted: function() {
    window.addEventListener("resize", this.menuHeight);
    this.menuHeight();
    setTimeout(() => {
      this.pageLoaded = true
    }, 200);
  },
  computed: {
    
  }
}
</script>
