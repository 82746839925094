<template>
	<div class="footer">
		<div class="footer-wrapper section">
			<router-link to="/" class="logo-container">
				<img class="logo" src="./../assets/logo.png">
			</router-link>
			<div class="footer-nav">
				<div class="column">
					<a href="tel:(+47) 452 69 375" class="hover-link">(+47) 452 69 375</a>
					<a href="mailto:post@funfinitum.no" class="hover-link">post@funfinitum.no</a>
					<ul class="socials">
						<li class="item">
							<a href="https://instagram.com/funfinitum?igshid=OGQ5ZDc2ODk2ZA==" target="__blank">
								<img src="./../assets/mainPage/instagramWhite.svg">
							</a>
						</li>
						<li class="item">
							<a href="https://www.facebook.com/funfinitum" target="__blank">
								<img src="./../assets/mainPage/fbWhite.svg">
							</a>
						</li>
					</ul>
				</div>
				<div class="row">
					<div class="column">
						<router-link class="hover-link" to="/price">{{$t('price')}}</router-link>
						<router-link class="hover-link" to="/characters">{{$t('characters')}}</router-link>
						<div @click="toAddParty()" class="hover-link">{{$t('what_to_add_to_your_party?')}}</div>
					</div>
					<div class="column">
						<router-link class="hover-link" to="/events">{{$t('reviews')}}</router-link>
						<router-link class="hover-link" to="/partnership">{{$t('partnership')}}</router-link>
						<router-link class="hover-link" to="/privacy">{{$t('privacy_policy')}}</router-link>
					</div>
				</div>
			</div>
			<div class="up-link">
				<div class="up-link-wrapper" v-scroll-to="'.header'">
					<img src="./../assets/mainPage/up.svg">
					<div class="desc">{{$t('up')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		toAddParty() {
			this.$emit('toAddParty');
			this.$router.push('/price');
		}
	},
	mounted() {
		
	}
}
</script>
