<template>
  <div class="price-page page-wrapper">
    <div class="page-content">
      <img class="banner" src="./../assets/programPage/banner.jpg">
      <div class="section section-1">
        <div class="section-wrapper">
          <img class="confetti" src="./../assets/programPage/confetti.svg">
          <div class="title">{{$t('Entertainment_programs')}}</div>
          <div class="card-list">
            <router-link :to="'/programs/' + item.id" class="card-list__item" v-for="(item, i) in programsLimited" :key="i">
              <img class="img" :src="require(`./../content_images/programs/${i + 1}/hero.jpg`)">
              <div class="card-list-wrapper">
                <div class="title">{{$t('programs_card_'+ item.id + '_title')}}</div>
                <ul class="list">
                  <li class="item" v-if="item && !$t('programs_card_'+ item.id + '_people_limit').includes('_')">
                    <img src="./../assets/programPage/circle.svg">
                    <div class="item-title">{{$t('programs_card_'+ item.id + '_people_limit')}}</div>
                  </li>
                  <li class="item" v-if="item && !$t('programs_card_'+ item.id + '_time_limit').includes('_')">
                    <img src="./../assets/programPage/square.svg">
                    <div class="item-title">{{$t('programs_card_'+ item.id + '_time_limit')}}</div>
                  </li>
                  <li class="item" v-if="item && !$t('programs_card_'+ item.id + '_age_limit').includes('_')">
                    <img src="./../assets/programPage/circle.svg">
                    <div class="item-title">{{$t('programs_card_'+ item.id + '_age_limit')}}</div>
                  </li>
                </ul>
                <div class="desc" v-if="item && !$t('programs_card_'+ item.id + '_short_desc').includes('_')">{{$t('programs_card_'+ item.id + '_short_desc')}}</div>
                <div class="price" v-if="item.price">{{$t(item.price)}} {{$t('currency')}}</div>
                <div class="price" v-if="item && !$t('programs_card_'+ item.id + '_special_price').includes('_')">{{$t('programs_card_'+ item.id + '_special_price')}}</div>
              </div>
            </router-link>
          </div>
          <button class="blue-button-stroke hover-link" v-if="programsAreLimited" @click="programsAreLimited = false">
            <div class="text">{{$t('show_more')}}</div>
          </button>
        </div>
      </div>
      <div class="section section-2">
        <div class="title">{{$t('characters')}}</div>
        <img class="baloons" src="./../assets/programPage/decor1.svg">
        <img class="mob-baloons" src="./../assets/programPage/decor4.svg">
        <div class="character-list">
          <template v-for="item in 100">
            <div class="character-list__item" :key="item" v-if="!$t('character_' + (item)).includes('_') && item < 7">
              <img :src="require(`./../content_images/characters/${item}.png`)">
              <div class="title">{{$t('character_' + (item))}}</div>
            </div>
          </template>
        </div>
        <router-link to="/characters" class="blue-button-stroke hover-link">
          <div class="text">{{$t('more_characters')}}</div>
        </router-link>
      </div>
      <div class="section section-3">
        <div class="section-wrapper">
          <div class="title">{{$t('what_to_add_to_your_party?')}}</div>
          <img class="decor-2" src="./../assets/programPage/decor2.svg">
          <img class="decor-2 mob" src="./../assets/programPage/decor3.svg">
          <div class="card-list">
            <div class="card-list__item" v-for="(item, i) in addPartyLimited" :key="i">
              <img class="img" :src="require(`./../content_images/addParty/${i + 1}.jpg`)">
              <div class="card-list-wrapper">
                <div class="title" v-if="item && !$t('addParty_card_'+ item.id + '_title').includes('_')">{{$t('addParty_card_'+ item.id + '_title')}}</div>
                <ul class="list">
                  <li class="item" v-if="item && !$t('addParty_card_'+ item.id + '_time_limit').includes('_')">
                    <img src="./../assets/programPage/square.svg">
                    <div class="item-title">{{$t('addParty_card_'+ item.id + '_time_limit')}}</div>
                  </li>
                </ul>
                <div class="desc" v-if="item && !$t('addParty_card_'+ item.id + '_short_desc').includes('_')">{{$t('addParty_card_'+ item.id + '_short_desc')}}</div>
              <div class="price" v-if="item.price">{{$t(item.price)}} {{$t('currency')}}</div>
              <div class="price" v-if="item && !$t('addParty_card_'+ item.id + '_special_price').includes('_')">{{$t('addParty_card_'+ item.id + '_special_price')}}</div>
              </div>
            </div>
          </div>
          <button class="blue-button-stroke hover-link" v-if="addPartyAreLimited" @click="addPartyAreLimited = false">
            <div class="text">{{$t('show_more')}}</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import programs from './../content/programs.json'
import addParty from './../content/addParty.json'

import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

export default {
  name: 'Price',
  metaInfo: {
    title: 'Magisk Barnebursdag med Prinsesser og Superhelter',
    meta: [
      {
        name: 'description',
        content: 'Barnas drømmebursdag - Velg mellom eventyrlige prinsesser, modige superhelter, og morsomme maskoter for å skape magiske øyeblikk og glade minner.'
      }
    ]
  },
  props: [],
  components: {
    
  },
  data: function() {
    return {
      programsLimited: programs,
      programsAreLimited: true,
      addPartyLimited: addParty,
      addPartyAreLimited: true
    }
  },
  watch: {
    programsAreLimited() {
      if (!this.programsAreLimited) {
        this.programsLimited = programs
      }
    },
    addPartyAreLimited() {
      if (!this.addPartyAreLimited) {
        this.addPartyLimited = addParty
      }
    }
  },
  mounted() {
    let self = this;
    this.programsLimited = this.programsLimited.slice(0,6);
    this.addPartyLimited = this.addPartyLimited.slice(0,6);

    if (self.$parent.addPartyState) {
      setTimeout(() => {
        VueScrollTo.scrollTo('.price-page .section-3');
        self.fromAddParty();
      }, 200);
      
    }
    
  },
  computed: {

  },
  methods: {
    toAddParty() {
			this.$emit('toAddParty');
		},
    fromAddParty() {
			this.$emit('fromAddParty');
		}
    
  }
}
</script>