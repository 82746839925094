<template>
  <div class="program-page page-wrapper">
    <div class="page-content">
      <div class="section section-1">
        <div class="up">
          <div class="inline-left">
            <div class="top top-mob">
              <div class="title" v-if="activeProgram.id">{{$t('programs_card_'+ activeProgram.id + '_title')}}</div>
              <div class="conditions">
                <div class="item" v-if="activeProgram && !$t('programs_card_'+ activeProgram.id + '_people_limit').includes('_')">
                  <img class="img" src="./../assets/singleProgramPage/children-icon.svg">
                  <div class="item-desc">{{$t('programs_card_'+ activeProgram.id + '_people_limit')}}</div>
                </div>
                <div class="item" v-if="activeProgram && !$t('programs_card_'+ activeProgram.id + '_time_limit').includes('_')">
                  <img class="img" src="./../assets/singleProgramPage/clock.svg">
                  <div class="item-desc">{{$t('programs_card_'+ activeProgram.id + '_time_limit')}}</div>
                </div>
                <div class="item" v-if="activeProgram  && !$t('programs_card_'+ activeProgram.id + '_age_limit').includes('_')">
                  <img class="img" src="./../assets/singleProgramPage/boy-icon.svg">
                  <div class="item-desc">{{$t('programs_card_'+ activeProgram.id + '_age_limit')}}</div>
                </div>
              </div>
            </div>
            <img v-if="activeProgram.id" class="img" :src="require(`./../content_images/programs/${activeProgram.id}/hero.jpg`)">
          </div>
          <div class="inline-right">
            <img class="img decor1" src="./../assets/singleProgramPage/decor1.svg">
            <img class="img baloon" src="./../assets/singleProgramPage/balloon_pink_pale.svg">
            <div class="top">
              <div class="title" v-if="activeProgram.id">{{$t('programs_card_'+ activeProgram.id + '_title')}}</div>
              <div class="conditions">
                <div class="item" v-if="activeProgram  && !$t('programs_card_'+ activeProgram.id + '_people_limit').includes('_')">
                  <img class="img" src="./../assets/singleProgramPage/children-icon.svg">
                  <div class="item-desc">{{$t('programs_card_'+ activeProgram.id + '_people_limit')}}</div>
                </div>
                <div class="item" v-if="activeProgram  && !$t('programs_card_'+ activeProgram.id + '_time_limit').includes('_')">
                  <img class="img" src="./../assets/singleProgramPage/clock.svg">
                  <div class="item-desc">{{$t('programs_card_'+ activeProgram.id + '_time_limit')}}</div>
                </div>
                <div class="item" v-if="activeProgram  && !$t('programs_card_'+ activeProgram.id + '_age_limit').includes('_')">
                  <img class="img" src="./../assets/singleProgramPage/boy-icon.svg">
                  <div class="item-desc">{{$t('programs_card_'+ activeProgram.id + '_age_limit')}}</div>
                </div>
              </div>
              <div class="desc" v-if="activeProgram && !$t('programs_card_'+ activeProgram.id + '_desc').includes('_')">{{$t('programs_card_'+ activeProgram.id + '_desc')}}</div>
              <div class="cta">
                <div class="price" v-if="activeProgram.price">{{$t(activeProgram.price)}} {{$t('currency')}}</div>
                <div class="price" v-if="activeProgram && !$t('programs_card_'+ activeProgram.id + '_special_price').includes('_')">{{$t('programs_card_'+ activeProgram.id + '_special_price')}}</div>
                <button class="blue-button hover-link" @click="openFormModal">{{$t('book')}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="down">
          <div class="inline-left">
            <img class="img baloon-small" src="./../assets/singleProgramPage/icon_balloon.svg">
            <div class="program-includes">
              <div class="title" v-if="!activeProgram.naVibor">{{$t('the_program_includes')}}:</div>
              <div class="title" v-else-if="activeProgram.naVibor == 'special'">{{$t('programs_card_5_includes_desc')}}:</div>
              <div class="title" v-else-if="activeProgram.naVibor">{{$t('the_program_includes_choose')}}:</div>
              <template v-for="item in 100"> 
                <div class="item" v-if="!$t('programs_card_' + activeProgram.id + '_includes_' + item).includes('_')" :key="item">
                  <img src="./../assets/programPage/square.svg">
                  <div class="desc">{{$t('programs_card_'+ activeProgram.id +'_includes_' + item)}}</div>
                </div>
                <div class="scenario" v-if="!$t('programs_card_' + activeProgram.id + '_includes_' + item + '_title').includes('_')" :key="item">
                  <div class="title">{{$t('programs_card_'+ activeProgram.id +'_includes_' + item + '_title')}}</div>
                  <template v-for="desc in 100"> 
                    <div class="item" v-if="!$t('programs_card_' + activeProgram.id + '_includes_' + item + '_desc' + desc).includes('_')" :key="desc">
                      <img src="./../assets/programPage/square.svg">
                      <div class="desc" >{{$t('programs_card_'+ activeProgram.id +'_includes_' + item + '_desc' + desc)}}</div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <div class="notice" v-if="activeProgram.planMoznoIzmenit">{{$t('single_program_notice')}}</div>
          </div>
          <div class="inline-right">
            <div class="title">
            <img class="img cap" src="./../assets/singleProgramPage/cap.svg">
            {{$t('Booking')}}:</div>
            <div class="desc">{{$t('single_program_notice2')}}</div>
          </div>
        </div>
        <div class="cta cta-mob">
          <img class="img baloon" src="./../assets/singleProgramPage/balloon_pink_pale.svg">
          <div class="price">{{$t(activeProgram.price)}} {{$t('currency')}}</div>
          <button class="blue-button hover-link" @click="openFormModal">{{$t('book')}}</button>
        </div>
      </div>
      <div class="section section-gallery">
        <div class="gallery" v-if="activeProgram.id && imgCount">
          <slick ref="slick" :options="slickOptions" class="carousel">
            <div class="item" v-for="item in imgCount" :key="item"
              @click="openGalleryModal(activeProgram.id, item)">
                <img v-if="item" class="img" :src="require(`./../content_images/programs/${activeProgram.id+'/'+item}.jpg`)">
            </div>
          </slick>
        </div>
      </div>
      <div class="section middle-section-character">
        <div class="title">{{$t('choose_any_character')}}</div>
        <div class="character-list">
          <template v-for="(item, i) in activeProgram.characters">
            <div class="character-list__item" :key="i" v-if="!$t('character_' + (item)).includes('_') && i < 3">
              <img :src="require(`./../content_images/characters/${item}.png`)">
              <div class="title">{{$t('character_' + (item))}}</div>
            </div>
          </template>
        </div>
        <router-link to="/characters" class="blue-button-stroke hover-link">
          <div class="text">{{$t('more_characters')}}</div>
        </router-link>
      </div>
      <div class="section middle-section-addition">
        <div class="title">{{$t('often_ordered_together')}}</div>
        <div class="card-list" v-if="addPartyLimited">
          <template v-for="item in addPartyLimited">
            <div class="card-list__item" :key="item.id">
              <img class="img" :src="require(`./../content_images/addParty/${item.id}.jpg`)">
              <div class="card-list-wrapper">
                <div class="title" v-if="item && !$t('addParty_card_'+ item.id + '_title').includes('_')">{{$t('addParty_card_'+ item.id + '_title')}}</div>
                <ul class="list">
                  <li class="item" v-if="item && !$t('addParty_card_'+ item.id + '_time_limit').includes('_')">
                    <img src="./../assets/programPage/square.svg">
                    <div class="item-title">{{$t('addParty_card_'+ item.id + '_time_limit')}}</div>
                  </li>
                </ul>
                <div class="desc" v-if="item && !$t('addParty_card_'+ item.id + '_short_desc').includes('_')">{{$t('addParty_card_'+ item.id + '_short_desc')}}</div>
                <div class="price" v-if="item.price">{{$t(item.price)}} {{$t('currency')}}</div>
                <div class="price" v-if="item && !$t('addParty_card_'+ item.id + '_special_price').includes('_')">{{$t('addParty_card_'+ item.id + '_special_price')}}</div>
              </div>
            </div>
            </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import programs from './../content/programs.json'
import Slick from 'vue-slick';
import '../../node_modules/slick-carousel/slick/slick.css';

import addParty from './../content/addParty.json'

export default {
  name: 'Program',
  metaInfo: {
    title: 'Barnebursdag som alle skal huske',
    meta: [
      {
        name: 'description',
        content: 'Opplev en uforglemmelig barnebursdag med Elsa fra Frost, Spiderman eller Paw Patrol. Garantert en minneverdig opplevelse.'
      }
    ]
  },
  props: [],
  components: {
    Slick
  },
  data: function() {
    return {
      imgCount: null,
      programs: programs,
      activeProgram: {},
      addParty: addParty,
      addPartyLimited: [],
      slickOptions: { 
        arrows: true,
        autoplay: false,
        autoplaySpeed: 1500,
        speed: 500,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      }
    }
  },
  computed: {
   
  },
  mounted: function() {
    let self = this;
  
    var h = self.programs;
    for (var i = 0; i < h.length; i++) {

      if (h[i].id == self.$route.params.id) {
        self.activeProgram = h[i];
        self.setActiveProgramId(h[i].id);
      }
    }
    if (self.activeProgram) {
      self.imgCount = require(`./../content_images/programs/${self.$parent.activeProgramId}/0.json`); 
    }
    if (self.activeProgram) {


      for (let i = 0; i < this.activeProgram.addParty.length; i++) {
        this.addPartyLimited.push(this.addParty[parseInt(this.activeProgram.addParty[i])-1])
      }
    }


    
  },
  methods: {
    openFormModal() {
			this.$emit('openFormModal');
		},
    setActiveProgramId(id) {
			this.$emit('setActiveProgramId', id);
		},
    openGalleryModal(id, imgIndex) {
      this.$emit('openGalleryModal', id, imgIndex);
    }
  }
}
</script>