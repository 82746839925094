import Vue from 'vue'
import VuewI18n from 'vue-i18n'
import no from './langs/no.json'

Vue.use(VuewI18n)

export const i18n = new VuewI18n({
	locale: 'no',
	fallbackLocale: 'no',
	messages: {
		no
	}
})