<template>
	<div class="header">
		<div class="header-wrapper">
			<div class="inline-left">
				<router-link to="/">
					<img class="logo" src="./../assets/logo.png">
				</router-link>
			</div>
			<div class="header-nav">
				<div class="header-nav-wrapper">
					<router-link class="hover-link" 
						:to="'/' + item"
						v-for="(item, index) in $parent.navList" :key="index"
					>{{$t(item)}}</router-link>
					<a href="tel:(+47) 452 69 375" class="hover-link phone">(+47) 452 69 375</a>
				</div>
			</div>
			<div class="inline-right">
				<ul class="header-langs">
					<li class="header-langs__item hover-link" 
					v-for="item in langs" 
					:key="item" 
					@click="$emit('changeLang', item)"
					:class="{'active': lang === item}"
					>{{$t(item)}}</li>
				</ul>
				<ul class="socials">
					<li class="item">
						<a href="https://instagram.com/funfinitum?igshid=OGQ5ZDc2ODk2ZA==" target="__blank">
							<img src="./../assets/mainPage/instagram.svg">
						</a>
					</li>
					<li class="item">
						<a href="https://www.facebook.com/funfinitum" target="__blank">
							<img src="./../assets/mainPage/fb.svg">
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Header',
	props: ['lang'],
	data: function() {
		return {
			langs: ['no', 'en', 'ru'],
			activePage: 'home'
		}
	},
	watch: {
		lang(val) {
			import(`../langs/${val}.json`).then((msgs) => {
				this.$root.$i18n.setLocaleMessage(val, msgs)
				this.$root.$i18n.locale = val
			})
		}
	},
	methods: {
		changeLang(l) {
			this.$emit('changeLang', l);
		},
		activePageChange(page) {
			this.$router.push('/' + page)
		}
	}
}
</script>
