<template>
  <div class="home-page page-wrapper">
		<img class="confetti" src="./../assets/mainPage/confetti.svg">
		<img class="confetti mob" src="./../assets/mainPage/confetti.svg">
		<img class="purple-baloon" src="./../assets/mainPage/Balloon_purple.svg">
		<img class="yellow-baloon" src="./../assets/mainPage/Balloon_yellow.svg">
    <img class="pale-baloon" src="./../assets/mainPage/Balloon_purple_pale.svg">
    <img class="pale-blob" src="./../assets/mainPage/blob-pale.svg">
    <div class="middle-img-container">
      <img class="middle-img" src="./../assets/mainPage/Minny-Mouse-min.png">
    </div>
    <div class="page-content">
      <div class="section section-1">
        <div class="inline-left">
          <h1 class="title">
            {{$t('home_title_part1', 1)}}<br/>{{$t('home_title_part2')}}<br/>
            <span>{{$t('home_title_part3')}}</span>
          </h1>
          <div class="desc">{{$t('home_title_part4')}}</div>
          <router-link class="hover-link blue-button" to="/characters">{{$t('characters')}}</router-link>
        </div>
        <div class="inline-right">
          <img class="img" src="./../assets/mainPage/Spiderman-min.png">
          <img class="pink-baloon" src="./../assets/mainPage/Balloon_pink.svg">
        </div>
      </div>
      <div class="section section-2">
        <ul class="list">
          <li class="item">
            <img src="./../assets/mainPage/icon_balloon.svg">
            <div class="desc">{{$t('main_page_section_2_desc1')}}</div>
          </li>
          <li class="item">
            <img src="./../assets/mainPage/icon_flags.svg">
            <div class="desc">{{$t('main_page_section_2_desc2')}}</div>
          </li>
          <li class="item">
            <img src="./../assets/mainPage/icon_confetti.svg">
            <div class="desc">{{$t('main_page_section_2_desc3')}}</div>
          </li>
        </ul>
      </div>
      <div class="section section-3">
        <div class="inline-left">
          <img class="pirate" src="./../assets/mainPage/Piratinblob-min.png">
          <img class="orange-baloon" src="./../assets/mainPage/Balloon_orange.svg">
          <img class="confettiRibbon" src="./../assets/mainPage/confetti-ribbon.svg">
        </div>
        <div class="inline-right">
          <img class="balloon-pink-pale" src="./../assets/mainPage/Balloon_pink_pale.svg">
          <div class="title">Funfinitum </div>
          <div class="desc">{{$t('main_page_section_3_desc')}}</div>
          <img class="elsa" src="./../assets/mainPage/Elsainblob-min.png">
          <img class="garland" src="./../assets/mainPage/garland.svg"> 
        </div>
      </div>
      <div class="section section-4">
        <ul class="list">
          <li class="item">
            <router-link to="/price">
              <div class="img-container">
                <img class="img" src="./../assets/mainPage/entertainmentProgram.jpg"> 
              </div>
              <div class="title">
                <div>{{$t('main_page_card1_title_part_1')}}</div>
                <div class="gradient">{{$t('main_page_card1_title_part_2')}}</div>
              </div>
            </router-link>
          </li>
          <li class="item">
            <router-link to="/characters">
              <div class="img-container">
                <img class="img" src="./../assets/mainPage/characters.jpg"> 
              </div>
              <div class="title">
                {{$t('main_page_card2_title')}}
              </div>
            </router-link>
          </li>
          <li class="item">
            <div @click="toAddParty()">
              <div class="img-container">
                <img class="img" src="./../assets/mainPage/howTocComplementTheHoliday.jpg"> 
              </div>
              <div class="title">
                {{$t('main_page_card3_title')}}
              </div>
            </div>
          </li>
          <li class="item">
            <router-link to="/partnership">
              <div class="img-container">
                <img class="img" src="./../assets/mainPage/partnerships.jpg"> 
              </div>
              <div class="title">
                {{$t('main_page_card4_title')}}
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Home',
  metaInfo: {
    title: 'Underholdning Events',
    meta: [
      {
        name: 'description',
        content: 'Velkommen til Fufinitum AS, din ledende leverandør av barneunderholdning i Haugesund!'
      }
    ]
  },
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    toAddParty() {
			this.$emit('toAddParty');
      this.$router.push('/price');
		}
  }
}
</script>