<template>
	<div class="menu">
		<div class="menu-wrapper">
			<div class="menu-nav">
				<a href="tel:(+47) 452 69 375" class="hover-link phone">(+47) 452 69 375</a>
				<router-link class="hover-link" 
					:to="'/' + item"
					v-for="(item, index) in $parent.navList" :key="index"
				>{{$t(item)}}</router-link>
			</div>
			<ul class="socials">
				<li class="item">
					<a href="https://instagram.com/funfinitum?igshid=OGQ5ZDc2ODk2ZA==" target="__blank">
						<img src="./../assets/mainPage/instagram.svg">
					</a>
				</li>
				<li class="item">
					<a href="https://www.facebook.com/funfinitum" target="__blank">
						<img src="./../assets/mainPage/fb.svg">
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
export default {
	name: 'Menu',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
