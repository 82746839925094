<template>
  <div class="page-not-found page-wrapper">
    <div class="page-content">
      <div class="section">
        <img class="img-404" src="./../assets/404.svg">
        <div class="text">
          <div class="title">{{$t('page_not_found_title')}}</div>
          <div class="title small">{{$t('page_not_found_title_small')}}</div>
          <div class="desc">{{$t('page_not_found_title_desc')}}</div>
          <div class="buttons">
            <router-link to="/price" class="blue-button-stroke hover-link">
              <div class="text">{{$t('main_page_card1_title_part_1')}}</div>
            </router-link>
            <router-link to="/" class="blue-button hover-link">
              <div class="text">{{$t('to_main_page')}}</div>
            </router-link>
            <router-link to="/characters" class="blue-button-stroke hover-link">
              <div class="text">{{$t('characters')}}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PageNotFound',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu')
    },
    setPageNotFound() {
      this.$emit('setPageNotFound')
    }
  },
  mounted: function() {
    this.setPageNotFound();
  }
}
</script>