<template>
  <div class="reviews-page partnership-page page-wrapper">
    <div class="page-content">
      <img class="banner" src="./../assets/partnership/hero.jpg">
      <div class="section section-1">
        <div class="section-wrapper">
          <img class="confetti" src="./../assets/programPage/confetti.svg">
          <div class="title">
            <img class="baloon" src="./../assets/partnership/balloon_pink_pale.svg">
            <span>{{$t('partnership')}}</span>
          </div>
          <div class="title small">{{$t('partnership_section1_title')}}</div>
          <div class="desc">{{$t('partnership_section1_desc')}}</div>
          <img class="icon" src="./../assets/mainPage/icon_balloon.svg">
        </div>
      </div>
      <div class="section partnership-section-2">
        <div class="inline-left">
          <img class="img" src="./../assets/partnership/unicornLilly.png">
        </div>
        <div class="inline-right">
          <div class="title">{{$t('we_offer')}}</div>
          <ul class="list">
            <template v-for="item in 100">
              <li class="item" v-if="!$t('partnership_list_item_' + (item + 1)).includes('_')" :key="item">{{$t('partnership_list_item_' + item)}}</li>
            </template>
          </ul>
        </div>
      </div>
      <div class="section section-gallery">
        <div class="gallery" v-if="imgCount">
          <slick ref="slick" :options="slickOptions" class="carousel">
            <div class="item" v-for="item in imgCount" :key="item"
              @click="openGalleryModal(0, item)">
                <img v-if="item" class="img" :src="require(`./../content_images/partnership/${item}.jpg`)">
            </div>
          </slick>
        </div>
      </div>
      <div class="section partnership-last-section">
        <div class="inline-left">
          <img class="img" src="./../assets/partnership/balloon_pink_pale2.svg">
          <div class="title">{{$t('contact_us_to_discuss')}}</div>
          <a href="mailto:post@funfinitum.no" class="desc hover-link">{{$t('email')}}: post@funfinitum.no</a>
          <a href="tel:(+47) 452 69 375" class="desc hover-link">{{$t('phone')}}: (+47) 452 69 375</a>
        </div>
        <div class="inline-right">
          <img class="img" src="./../content_images/characters/5.png">
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Slick from 'vue-slick';
import '../../node_modules/slick-carousel/slick/slick.css';


import imgCount from './../content_images/partnership/0.json'
export default {
  name: 'Partnership',
  metaInfo: {
    title: 'Barneunderholdning på kjøpesentre og festivaler i Norge',
    meta: [
      {
        name: 'description',
        content: 'Vi har et fantastisk lag med artistiske og dyktige personer som elsker å spre glede. Uansett event, gjør vi alt med top kvalitet! '
      }
    ]
  },
  props: [],
  components: {
    Slick
  },
  data: function() {
    return {
      imgCount: imgCount,
      slickOptions: { 
        arrows: true,
        autoplay: false,
        autoplaySpeed: 1500,
        speed: 500,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      }
    }
  },
  methods: {
    openGalleryModal(id, imgIndex) {
      this.$emit('openGalleryModal', id, imgIndex);
    }
  }
}
</script>