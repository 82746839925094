<template>
  <div class="privacy-page page-wrapper">
    <div class="page-content">
      <div class="section section-1">
        <div class="title">{{$t('privacy_policy')}}</div>
        <div v-if="this.$i18n.locale == 'ru'" class="desc">
          Эта политика конфиденциальности содержит информацию о том, как мы обрабатываем вашу личную информацию. 
Мы рекомендуем вам внимательно прочитать политику конфиденциальности. 

<b>1. Что такое файлы Cookie?</b>

Cookies — это небольшие текстовые файлы у нас на компьютерах, в которых хранится информация о наших предыдущих действиях на сайтах.
Некоторые из них могут быть необходимы для работы веб-сайта, в то время как другие помогают нам улучшить наши услуги.

Хранение и обработка этой информации не разрешено, если пользователь не был проинформирован и не дал свое согласие на обработку данных. Пользователь должен дать согласие и быть проинформирован о том, какая информация обрабатывается, какова цель обработки и кто обрабатывает информацию.

Основная цель файла cookie — упростить использование нашего веб-сайта для вас и, таким образом, улучшить взаимодействие с пользователем.

Файл cookie не является программой, содержащей вредоносные процессы или вирусы.


<b>2. Что такое персональные данные и кто их обрабатывает?</b>

Персональные данные — это информация, которая отдельно или вместе с другой информацией может быть использована для идентификации человека, его местонахождения или связи с ним. Такие персональные данные могут включать: имя, адрес электронной почты, ip-адрес пользователя, номер телефона. Обработка персональных данных включает в себя все формы обращения с персональными данными, такие как: сбор, анализ, регистрация и хранение. Контроллер данных — это тот кто взаимодействует с клиентом, собирает данные и определяет каким образом их дальше обрабатывать.Контроллер данных несет ответственность за обеспечение того, чтобы обработка ваших личных данных происходила в соответствии с действующим законом «О персональных данных».

В отношении ваших персональных данных, мы выступаем в качестве контроллера данных, определяя цели и методы обработки ваших персональных данных.

Мы стремимся предоставить вам как можно больше информации о наших действиях с вашими данными. Когда вы предоставляете нам свои личные данные, вы должны знать, как они будут использоваться.

<b>3. Сбор персональных данных</b>

Когда вы заказываете услугу / продукт или посещаете наш веб-сайт, вас могут попросить предоставить информацию.
<ul>
<li>Имя</li>
<li>Номер телефона</li>
<li>Адрес</li>
<li>Адрес электронной почты</li>
<li>В каком фирме вы работаете (корпоративные клиенты)</li>
<li>Техническая информация: ваш IP-адрес, поведенческие данные, тип браузера, язык, информация об идентификации человека и операционной системы.</li>
</ul>
<b>4. Правовая основа для обработки ваших персональных данных</b>

Мы основаны на законном согласии, основанном в Разделе 6f  закона “О конфиденциальности”. Если вы уже вступали с нами в клиентские отношения (или являетесь новым клиентом) это будет законной причиной для отправки вам информации. Это приравнивается к согласию в соответствии с законом “О маркетинге”, поскольку закон действует в Норвегии уже много лет. Вы можете отозвать согласие на обработку ваших данных в любое время. Если вы отзовете свое согласие, мы удалим имеющуюся у нас информацию о вас. Обратите внимание, что удаление информации может повлиять на доставку нужной вам информации в рамках отношения с клиентом, а также повлиять на текущий диалог.

<b>5. Информация из других источников</b>

Когда вы соглашаетесь с тем, что мы обрабатываем ваши персональные данные, вы также соглашаетесь с тем, что мы можем зарегистрировать другую информацию о вас, которую вы предоставили нам ранее. На основе общедоступной информации мы также можем дополнить вашу зарегистрированную информацию дополнительной контактной информацией. Если вы являетесь нашим клиентом, мы также можем добавить дополнительную контактную информацию к вашей информации, которую вы зарегистрировали у нас, например, через телефонный разговор. Информация, необходимая для услуг, которые вы у нас заказываете, также будет храниться.

<b>6. Цель обработки персональных данных</b>
Предоставленная вами информация используется для следующих целей:
<ul>
<li>Обслуживание клиентов и информация о наших услугах</li>
<li>Статистика и информация о поведении пользователей, чтобы улучшить как веб-сайт, так и сам пользовательский опыт.</li>
</ul>
Мы запрашиваем вашу личную информацию, чтобы:
<ul>
<li>Отвечать на запросы</li>
<li>Адаптировать наши услуги к вашим пожеланиям</li>
<li>Отправлять информацию, которая может вас заинтересовать</li>
</ul>
<b>7. Согласие на переписку по электронной почте и дальнейший контакт</b>
Давая согласие на обработку ваших персональных данных в соответствии с вышеуказанными целями, вы соглашаетесь со следующим:
<ul>
<li>Мы обрабатываем вашу личную информацию в соответствии с этим заявлением о конфиденциальности</li>
<li>Мы можем связаться с вами по электронной почте или телефону</li>
</ul>
<b>8. Срок хранение персональных данных</b>

Мы обрабатываем персональные данные только до тех пор, пока это необходимо для достижения цели их сбора, затем мы удаляем информацию.
Если вы ведете с нами активный диалог, мы храним вашу информацию до 2 лет с момента последнего контакта; затем мы удалим имеющуюся у нас информацию о вас. Под активным диалогом подразумевается то, что вы взаимодействовали с нами в течение последних 2 лет по телефону, электронной почте, в социальных сетях или вы регистрировались через форму.

<b>9. Передача персональных данных</b>

Мы ни с кем не делимся вашей информацией.

<b>10. Обмен информацией с третьими лицами</b>

Мы ни в коем случае не продаем ваши персональные данные третьим лицам. Если текущий заказ осуществляется между вами, нами и любым из наших партнеров, то мы можем делиться следующей информацией:
<ul>
<li>Имя</li>
<li>В каком фирме вы работаете (корпоративные клиенты)</li>
</ul>
<b>11. Где хранятся ваши персональные данные?</b>

Информация хранится в бухгалтерской системе, которую использует Funfinitum Suhareva (org.nr. 926 655 191).

<b>12. Права Пользователя</b>

Вы имеете право на доступ к своим персональным данным и можете запросить обзор информации, которую мы храним о вас. Вы также имеете право запросить исправление ошибок в ваших персональных данных, которые имеются у нас.

Кроме того, вы имеете право запросить удаление ваших персональных данных, а также ограничить или возразить против обработки ваших персональных данных в соответствии с данной политикой о конфиденциальности.

Используйте адрес электронной почты, указанный на нашем веб-сайте, чтобы связаться с нами по вопросам, изложенным в этом разделе.

<b>13. Другие цели сбора данных</b>
Если мы будем использовать персональные данные для других целей (отличных от тех, которые указанны в данной Политики Конфиденциальности), то мы обязаны указать новые цели сбора данных и переписать те пункты, которые будут недействительны.

<b>14. Электронная почта и телефон</b>

Мы используем электронную почту и телефон как часть нашей повседневной работы. Важная информация о заказе, которую предоставляют нам клиенты через телефонный разговор или переписку, тоже регистрируется.

Мы также используем электронную почту в общении с внутренними и внешними контактами. Мы несем ответственность за удаление сообщений, которые больше не актуальны. И не реже одного раза в год, просматриваем и удаляем ненужный контент в почтовом ящике.

Конфиденциальная личная информация не должна отправляться по электронной почте.

Мы хотим напомнить вам, что обычная электронная почта не зашифрована. Поэтому мы не рекомендуем вам отправлять какую-либо конфиденциальную информацию по электронной почте.

        </div>
        <div v-if="this.$i18n.locale == 'en'" class="desc">

This Privacy Policy contains information about how we handle your personal data. We encourage you to read carefully through the Privacy Policy.

<b>1. What is a Cookie?</b>

Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. 

Our website uses these “cookies” to collect information and to improve our services. There are different types of cookies and we use them for various tasks. However, in general, cookies help us distinguish you from other users of our website. This helps us to improve our website and our services. 
<b>2. What is personal data and who is controlling it?</b>
Personal data means any information that pertains to a person who is identified or identifiable (data subject). Personal data include any kinds of information that makes it possible to directly or indirectly identify a person, for example, that person’s name, telephone number or IP adress.
In relation to your personal data, we act as the data controller, determining the purposes and methods of processing your personal data. 
It is our intention to provide you with as much information about our activities with these personal data as possible. So when you provide us with your personal data, you are aware of how they will be used.
<b>3. What kind of information do we collect?</b>
When you order a service/product from us or visit our website, you may be asked to provide information.
<ul>
<li>Name</li>
<li>Telephone number </li>
<li>Address</li>
<li>E-mail address</li>
<li>Which company you work for (business customers) </li>
<li>Technical information: your IP address and behavioral data, browser type, language, identification information, operating system.</li>
</ul>

<b>4. The legal basis for processing your personal data</b>

We are based on legitimate consent of Paragraph 6 f the Privacy Act. If you have had customer relationship with us before (or you are a new customer) this will be a legitimate reason to send you information about our services. This is equated with a consent in accordance with the Marketing Act as the law has been in Norway for many years. You can withdraw this consent at any time, if you want us to stop processing your personal data. If you withdraw your consent, we will remove the information we have about you. Please note that deleting the information from our system may affect the delivery of information within the framework of the customer relationship, as well as affect ongoing dialogues.
<b>5. Information from other sources</b>
When you agree on processing of your personal information, you also agree that we may register other information about you that you have provided to us on a previous occasion. Based on publicly available information, we may also supplement your registered information with other additional contact information. If you are our customer, we can also add some additional contact information to the information, which you have provided to us via telephone or e-mail. Important information needed for the services you wish to order, may also be stored.

<b>6. Processing of  personal data</b>
The information you provide to us is used for the following purposes:
<ul>
<li>Customer management and information about our services</li>
<li>Statistics and behavior analytics to improve  the quality, functionality and user experience of our products/ services.</li>
</ul>
We ask for your personal information to: 
<ul>
<li>Respond to requests </li>
<li>Customize our services to your wishes</li>
<li>Send some information that you might be interested in </li>
</ul>
<b>7. E-mail correspondence and further contact</b>
When you agree of processing your personal data in accordance with the above purposes, you also agree to the following: 
<ul>
<li>We process your personal information in accordance with this Privacy Policy </li>
<li>We can contact you by e-mail or telephone </li>
</ul>
<b>8. How long is your data stored?</b>
We will only store your personal information as long as required to fulfill the purpose of their collection. Then we delete the information.
We save your information for up to 2 years from the last contact, if you have an active dialogue with us; then your personal data will be deleted. By «active dialogue» we mean: when you have interacted with us in the last 2 years by phone, e-mail, social media or registered via a form. 
<b>9. How is your personal data shared?</b>
We do not share your information with anyone else.
<b>10. Sharing information with third-party</b>
We do not sell or share your personal information with third parties.  If there is an open dialog between you, us and vendors or our collaboration partners, then we can share the following information:
<ul>
<li>Name</li>
<li>Company you work in (business customer)</li>
</ul>

<b>11. Where is your personal information stored?</b>
The information is stored by Funfinitum Suhareva (org.nr. 926 655 191) in our invoice system.
<b>12. What are your rights?</b>
You have the right to access your personal data by requesting an overview of the personal data we process about you and you may have a right to data portability. You also have the right to request that we correct inaccuracies in your personal data. 
Further, you have a right to request deletion of personal data, and to restrict or object to our processing of your personal data according to this Privacy Statement or other service-specific terms.
Please use our e-mail, which you can find on our website, to file requests as mentioned in this section.
<b>13. Other purposes</b>
If we are to use the personal data for any other purpose than the purposes we described above, we must state the new purposes of processing your data and rewrite paragraphs that include new information.
<b>14. Email and phone</b>
We use e-mail and telephone as part of our daily work. Relevant information, which emerges from calls and e-mail exchanges during conversation with customers, is registered.
We also use e-mail in general dialogue with internal and external contacts. We are deleting messages that are no longer relevant, and at least every year reviewing and deleting unnecessary content in the e-mail box.
Sensitive personal information should not be sent by e-mail.
We would like to remind you that ordinary e-mails are unencrypted. We therefore do not encourage you to send sensitive or other confidential information via e-mail.

        </div>
        <div v-if="this.$i18n.locale == 'no'" class="desc">

Denne personvernerklæringen inneholder informasjon om hvordan vi håndterer dine personopplysninger. 

Vi oppfordrer deg til å lese nøye gjennom personvernerklæringen. 


<b>1. Hva er en informasjonskapsel?</b>
Informasjonskapsler (cookies) er små tekstfiler som lagres på din enhet og samler inn informasjon. Noen kan være nødvendige for at nettsiden skal fungere, mens andre hjelper oss med å tilpasse og skreddersy tjenesten best mulig for deg. 

Lagring av opplysninger og behandling av disse opplysningene er ikke tillatt med mindre bruker både har blitt informert om og har gitt sitt samtykke til behandlingen. Brukeren skal få vite om og godkjenne hvilke opplysninger som behandles, hva formålet med behandlingen er og hvem som behandler opplysningene.

Hovedformålet med informasjonskapselen er å forenkle bruken av nettstedet vårt for deg, og dermed forbedre brukeropplevelsen.
En informasjonskapsel er ikke et program som inneholder skadelig prosesser eller virus.
<b>2. Sentrale begreper</b>
Personopplysninger er informasjon som alene eller sammen med andre opplysninger kan brukes til å identifisere, lokalisere eller kontakte en person. Eksempler på personopplysninger er navn, telefonnummer og IP-adresse. Behandling av personopplysninger innebærer alle former for håndtering av personopplysninger som: innsamling, analyse, registrering og lagring. Behandlingsansvarlig er den som bestemmer formålet med behandlingen av personopplysninger og hvilke opplysninger som anmodes. Det er behandlingsansvarlig som er ansvarlig for at håndteringen av dine personopplysninger skjer ut i fra den gjeldende personopplysningsloven.
<b>3.Behandlingsansvarlig</b>
Funfinitum Suhareva (org.nr. 926 655 191) er behandlingsansvarlig for håndteringen av dine personopplysninger.
<b>4. Hva slags informasjon er det vi samler inn?</b>
Når du bestiller en tjenste/produkt eller besøker vår hjemmeside, kan du bli bedt om å gi fra deg informasjon. 
<ul>
<li>Navn</li>
<li>Telefonnummer</li>
<li>Adresse</li>
<li>E-postadresse</li>
<li>Hvilken virksomhet du jobber for (bedriftskunder)</li>
<li>Tekniske opplysninger: din IP-adresse og brukeradferd, type nettleser, språk, informasjon om identifisering og operativsystem. </li>
</ul>
<b>5. Rettslig grunnlag for behandling av dine personopplysninger</b>
Vi tar utgangspunkt i legitimt samtykke utifra Paragraf 6f i personvernloven. Hvis du har inngått et kundeforhold med oss tidligere, eller du er en ny kunde vil dette være en legitim grunn til å sende deg informasjon. Dette sidestilles med et samtykke i henhold til markedsføringsloven slik loven har vært i Norge i mange år. Du kan når som helst trekke dette samtykket om at vi skal behandle dine personopplysninger. Trekker du tilbake samtykket, vil vi ta bort de opplysningene vi har om deg. Merk at sletting av informasjonen hos oss kan påvirke leveransen av informasjon innenfor rammen for kunderelasjonen, samt påvirke pågående dialoger
<b>6. Informasjon fra andre kilder</b>
Når du samtykker til at vi behandler dine personopplysninger godkjenner du også at vi kan registrere andre opplysninger om deg som du har gitt oss ved en tidligere anledning. Basert på offentlig tilgjengelig informasjon kan vi også supplere dine registrerte opplysninger med bransje og ytterligere kontaktinformasjon. Hvis du er kunde hos oss kan vi også tilføye ytterligere kontaktinformasjon til dine opplysninger, som du har registrert hos oss via f.eks. telefon til kundeservice. Opplysninger som er nødvendige for de tjenester du skal bruke vil også bli lagret.
<b>7. Formålet med behandlingen av personopplysningene</b>
Informasjonen som du gir fra deg brukes til følgende formål:
<ul>
<li>Kundebehandling og informasjon om våre tjenester</li>
<li>For å få statistikk og opplysninger om brukeratferd for å forbedre både hjemmesiden og selve brukeropplevelsen.</li>
</ul>
Vi ber om dine personopplysninger for å:
<ul>
<li>Svare på forespørsler</li>
<li>Tilpasse våre tjenester til dine ønsker</li>
<li>Sende informasjon som kan være av interesse for deg</li>
</ul>
<b>8. Samtykke til e-postkorrespondanse og videre kontakt</b>
Når du samtykker til at vi behandler dine personopplysninger i overensstemmelse med de overnevnte formål, samtykker du til følgende:
<ul>
<li>Vi behandler dine personopplysninger i henhold til denne personvernerklæringen</li>
<li>Vi kan kontakte deg via e-post eller telefon</li>
</ul>
<b>9. Hvor lenge lagres opplysningene?</b>
Vi behandler personopplysningene bare så lenge det tar å oppfylle hensikten med deres innsamling, deretter sletter vi informasjonen.
Har du en aktiv dialog med oss tar vi vare på opplysningene dine i opptil 2 år fra den siste kontakten; deretter fjerner vi opplysningene vi har om deg. En aktiv dialog defineres som at du har hatt interaksjon med oss de siste 2 årene via telefon, e-post, sosiale medier eller registrert deg via et skjema.
<b>10. Hvem kan informasjonen deles med?</b>
Vi deler ikke din informasjon med noen andre.
<b>11. Informasjonsdeling med tredjepart</b>
Vi selger ikke dine personopplysninger til tredjepart. Hvis det er en pågående salg- eller kundedialog mellom deg, oss og noen av våre samarbeidspartnere, kan vi dele informasjon som:
<ul>
<li>Navn</li>
<li>Hvilken virksomhet du jobber for (bedriftskunder)</li>
</ul>
<b>12. Hvor oppbevares opplysningene?</b>
Opplysningene oppbevares av Funfinitum Suhareva (org.nr. 926 655 191) i sitt fakturasystem.
<b>13. Dine rettigheter </b>
Du har rett til innsyn i dine personopplysninger og kan be om en oversikt over de personopplysningene vi lagrer om deg. Du har også rett til å be om korrigering av feil i dine personopplysninger. 
Videre har du rett til å be om sletting av personopplysninger og til å begrense eller motsette deg behandling av dine personopplysninger i henhold til denne personvernerklæringen eller andre tjenestespesifikke vilkår.
Vennligst bruk e-postadressen som du finner på vår nettside, for å komme i kontakt med oss angående punktene i dette avsnittet.
<b>14. Andre formål</b>
Dersom vi skal bruke personopplysningene til et annet formål enn det de ble samlet inn for inntrer informasjonsplikten på nytt, og vi må da opplyse hva det nye formålet er og gi deler av informasjonen ovenfor på nytt.
<b>15. E-post og telefon</b>
Vi benytter e-post og telefon som en del av det daglige arbeidet. Relevante opplysninger om bestillingen, som fremkommer av telefonsamtaler og e-postutveksling som skjer ved kundebehandlingen, registreres.
Vi benytter i tillegg e-post i alminnelig dialog med interne og eksterne kontakter. Vi er ansvarlige for å slette meldinger som ikke lenger er aktuelle, og minst hvert år gjennomgå og slette unødvendig innhold i e-postkassen. 
Sensitive personopplysninger skal ikke sendes med e-post.
Vi gjør deg oppmerksom på at vanlig e-post er ukryptert. Vi oppfordrer deg derfor ikke til å sende taushetsbelagte, sensitive eller andre fortrolige opplysninger via e-post.




        </div>
      </div>
      
    </div>
  </div>
</template>
<script>

export default {
  name: 'Privacy',
  props: [],
  components: {
    
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>