import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './pages/Home.vue'
import Characters from './pages/Characters.vue'
import Price from './pages/Price.vue'
import Reviews from './pages/Reviews.vue'
import Partnership from './pages/Partnership.vue'
import PageNotFound from './pages/PageNotFound.vue'
import Program from './pages/Program.vue'
import Privacy from './pages/Privacy.vue'


Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [
		{ path: '/', redirect: '/home' },
		{
			path: '/home',
			name: 'Home',
			component: Home
		},
		{
			path: '/characters',
			name: 'Characters',
			component: Characters
		},
		{
			path: '/price',
			name: 'Price',
			component: Price
		},
		{
			path: '/programs/:id',
			name: 'Program',
			component: Program
		},
		{
			path: '/events',
			name: 'Reviews',
			component: Reviews
		},
		{
			path: '/partnership',
			name: 'Partnership',
			component: Partnership
		},
		{
			path: '/privacy',
			name: 'Privacy',
			component: Privacy
		},
		{ 
			path: "*", 
			name: 'PageNotFound',
			component: PageNotFound,
			props: {title: "PageNotFound"}
		},
	]
})

